<template>
  <div>
   <v-row class="gradient-privacy ma-0"  :class="$vuetify.breakpoint.xsOnly ? 'apply-privacy-height': ''">
    <v-container :class="$vuetify.breakpoint.lgAndUp ? 'common-width': ''">
      <template v-for="(item, index) in privacyPolicy.privacy_policy_heading || []">
        <v-row class="ma-0" :class="$vuetify.breakpoint.mdAndDown ? 'apply-column' : ''" :key="`${index}`">
          <v-col class=" d-flex align-center" xl="6" lg="6" xs="12" sm="12" md="12">
            <v-container>
              <h1 class="font-weight-bold mb-4" style="color:#ffffff !important;"
              :class="$vuetify.breakpoint.smAndUp ? 'text-h2': 'text-h6'">{{item.title}}</h1>
            </v-container>
          </v-col>
          <v-col xl="6" lg="6" xs="12" sm="12" md="12">
            <!-- <v-img contain  :height=" $vuetify.breakpoint.xsOnly ? '' : 600" src="../../assets/images/privacyPolicy (1).png"></v-img> -->
            <v-img contain :aspect-ratio=" $vuetify.breakpoint.mdAndDown ?  2 : ''" :height=" $vuetify.breakpoint.xsOnly ? '' : 600" :src="item.image"></v-img>
          </v-col>
        </v-row>
      </template>
    </v-container>
    <!-- <square-animation></square-animation> -->
   </v-row>
   <v-row class="ma-0">
    <v-container :class="$vuetify.breakpoint.lgAndUp ? 'common-width': ''">
      <v-row class="pa-xl-12 pa-2" :class="$vuetify.breakpoint.mdAndDown ? 'apply-column' : ''">
        <v-col xs="12" lg="12" xl="12" md="12" sm="12" v-for="(item, index) in  privacyPolicy.privacy_policy_list" :key="index">
          <p class="text-left pa-0 font-weight-bold">{{ item.title }}</p>
          <p class="text-left pa-0" v-html="item.description"></p>
        </v-col>
      </v-row>
    </v-container>
    </v-row>
  </div>
</template>
<script>
import { api } from '../../plugins/axios'
export default {
  data () {
    return {
      privacyPolicy: {}
    }
  },
  mounted () {
    this.getPrivacyPolicy()
    this.scrollToTop()
  },
  // components: {
  //   'square-animation': () => import('../SquareAnimation/index.vue')
  // },
  methods: {
    getPrivacyPolicy () {
      api.get('/privacy-policy')
        .then((response) => {
          this.privacyPolicy = response.data
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    },
    scrollToTop () {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Adds smooth scrolling behavior
      })
    }
  }
}
</script>

<style>
.gradient-privacy {
 background: linear-gradient(to right, #1f1ccf, #c0c9db);
}
.apply-privacy-height {
  height:  50vh !important;
}

</style>
